import React from 'react';
import PropTypes from 'prop-types';

// Helpers
import { getFreiplatzmeldung } from '../helpers/freiplatzmeldung';

const initialState = {
  freiplatzmeldung: [],
  hasClosedSidebar: true,
};

const params = {
  api_dachverband: '0',
  api_traegergesellschaft: '0',
  api_filter: 'no',
  api_traegergruppe: '0',
  api_searchmode: '971',
  api_account: '971',
};

export const SiteContext = React.createContext(initialState);

export class SiteProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.changeSidebarStatus = this.changeSidebarStatus.bind(this);
    this.openSidebar = this.openSidebar.bind(this);
    this.closeSidebar = this.closeSidebar.bind(this);
  }

  changeSidebarStatus = () => {
    this.setState({
      hasClosedSidebar: !this.state.hasClosedSidebar,
    });
  };

  openSidebar = (content) => {
    this.setState({
      hasClosedSidebar: false,
      sidebarContent: content,
    });
    document.querySelector('body').classList.add('overflow-hidden');
  };

  closeSidebar = () => {
    this.setState({
      hasClosedSidebar: true,
    });
    document.querySelector('body').classList.remove('overflow-hidden');
  };

  async componentDidMount() {
    try {
      const freiplatzmeldung = await getFreiplatzmeldung(params);

      if (freiplatzmeldung) {
        this.setState({
          freiplatzmeldung: freiplatzmeldung,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { children } = this.props;

    return (
      <SiteContext.Provider
        value={{
          ...this.state,
          changeSidebarStatus: this.changeSidebarStatus,
          openSidebar: this.openSidebar,
          closeSidebar: this.closeSidebar,
        }}
      >
        {children}
      </SiteContext.Provider>
    );
  }
}
SiteProvider.propTypes = {
  children: PropTypes.node,
};

export const SiteConsumer = SiteContext.Consumer;
