import './src/style/tailwind.css';
import './src/style/index.scss';
import React from 'react';
import PropTypes from 'prop-types';

import { ApolloProvider } from 'react-apollo';
import { client } from './src/apollo/client';

// Context
import { SiteProvider } from './src/context/SiteContext';
import { ViewportProvider } from './src/context/ViewportContext';

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <SiteProvider value={[]}>
        <ViewportProvider value={[]}>{element}</ViewportProvider>
      </SiteProvider>
    </ApolloProvider>
  );
};

wrapRootElement.propTypes = { element: PropTypes.any };
