import axios from "axios";

const getFreiplatzmeldung = async (params) => {
  try {
    const rsp = await axios.get(
      "https://fsnw.de/api/freiplatzmeldung.php",
      { params }
    );

    if (rsp) {
      const trimmed = rsp.data
        .replace(/(#linefeed#)/g, "")
        .replace(/\t/g, "")
        .trim();
      const cleaned = trimmed
        .match(/{([^}]+)}/g)
        .map((res) => res.replace(/{|}/g, ""));
      const parsed = cleaned[0].split('" : "')[1].replace(/\\'/g, '"');

      const parser = new DOMParser();
      const doc = parser.parseFromString(parsed, "text/html");
      const sections = doc.querySelectorAll(".sin-offer-box");

      const freiplatzmeldungen = [];
      let i = 1;
      sections.forEach((section) => {
        const data = {};
        data.id = i;
        data.title = section.querySelector(".offertitle").textContent;
        data.currentCapacity =
          parseInt(
            section.querySelector(".kapazitaeten .big-number").textContent,
            10
          ) || 0;
        data.details = [];

        const details = section.querySelectorAll("dl");
        details.forEach((el) => {
          const key = el.querySelector("dt").textContent;
          const value = el.querySelector("dd").textContent;

          data.details.push([key, value]);
        });
        freiplatzmeldungen.push(data);
        i++;
      });

      return freiplatzmeldungen;
    }
  } catch (e) {
    console.log("error fetching freiplatzmeldung");
    return [];
  }
};

export { getFreiplatzmeldung };
