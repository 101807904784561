import React from "react";
import PropTypes from "prop-types";

const initialState = {
  scrollTop: 0,
  width: 0,
  height: 0,
};

export const ViewportContext = React.createContext(initialState);

export class ViewportProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.determineScroll = () => {
      this.setState({
        scrollTop: window.scrollY,
      });
    };

    this.determineViewport = () => {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
  }

  async componentDidMount() {
    window.addEventListener("scroll", this.determineScroll);
    window.addEventListener("resize", this.determineViewport);

    this.setState({
      scrollTop: window.scrollY,
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.determineScroll);
    window.removeEventListener("resize", this.determineViewport);
  }

  render() {
    const { children } = this.props;

    return (
      <ViewportContext.Provider
        value={{
          ...this.state,
        }}
      >
        {children}
      </ViewportContext.Provider>
    );
  }
}
ViewportProvider.propTypes = {
  children: PropTypes.node,
};

export const ViewportConsumer = ViewportContext.Consumer;
